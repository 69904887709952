import Sortable from "sortablejs";

export default class PhotoSorting {
  constructor(sorted_list_selector = "#sortable") {
    this.sorted_list_el = document.querySelector(sorted_list_selector);
    this.options = this.sorted_list_el.dataset;
    if(document.getElementsByClassName("sort-item").length !== 0) {
      this.init();
    }
  }

  init() {
    var el = document.getElementById("sortable");
    var sortable = Sortable.create(el, {
      animation: 200,
      delay: 50,
      delayOnTouchOnly: true,
      draggable: ".sort-item",
      filter: ".sort-ignore",
      onMove: (event) => {
        return !event.related.classList.contains(".sort-ignore");
      },
      onUpdate: (event) => {
        this.reorder();
      },
    });
  }

  reorder() {
    let photo_ids = Array.from(document.getElementsByClassName("sort-item"))
      .map((el) => {
        return el.dataset["photo_id"];
      })
      .reverse()
      .join(",");

    PhotoSorting.reorder_photo_request(
      this.options.action,
      this.options.accessToken,
      this.options.businessId,
      this.options.eventId,
      photo_ids,
      function (data) {},
      function (e) {}
    );
  }

  static reorder_photo_request(
    action,
    token,
    business_id,
    event_id,
    photo_ids,
    success_func,
    error_func
  ) {
    $.ajax({
      type: "POST",
      headers: { Authorization: "Bearer " + token },
      url: action,
      data: {
        business_id: business_id,
        event_id: event_id,
        photo_ids: photo_ids,
      },
      timeout: 600000,
      success: success_func,
      error: error_func,
    });
  }
}
