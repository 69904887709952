import PhotoUploader from "./photo_uploader";

export default class Events {
  constructor() {
    console.log("Events::constructor");
    this.init();
  }
  init() {
    const editorToolbar = document.getElementById('trix-toolbar-1')
    if (editorToolbar) {
      editorToolbar.querySelector('.trix-button--icon-strike').style.display = 'none'
      editorToolbar.querySelector('.trix-button--icon-heading-1').style.display = 'none'
      editorToolbar.querySelector('.trix-button--icon-quote').style.display = 'none'
      editorToolbar.querySelector('.trix-button--icon-code').style.display = 'none'
      editorToolbar.querySelector('.trix-button-group--file-tools').style.display = 'none'
    }

    const currentURL = window.location.pathname.split('/').pop()
    const relevantBtn = document.getElementById('relevantBtn')
    const outdatedBtn = document.getElementById('outdatedBtn')
    if (relevantBtn && outdatedBtn) {
      if (currentURL === 'relevant') {
        relevantBtn.classList.add('active')
      } else {
        relevantBtn.classList.remove('active')
        outdatedBtn.classList.add('active')
      }
    }

    if (document.getElementById('events-new')) {
      const required = document.querySelectorAll('.text-input[required]')
      const submitBtn = document.getElementById('newEvent')
      const errorBox = document.getElementById('errorBox')

      submitBtn.addEventListener('click', ()=> {
        for (let item of required) {
          if (item.value.length === 0) {
            errorBox.classList.remove('error-required__hide')
            if (window.matchMedia("(max-width: 992px)")) {
              errorBox.style.marginTop = '30px'
            }
            item.classList.add('error-field')
            item.addEventListener('input', ()=> {
              if (item.value.length > 0) {
                item.classList.remove('error-field')
              }
            })
          }
        }
      })
    }


    if (document.getElementById('events-edit')) {

      const imagesContainer = document.getElementById('sortable')
      const hasImage = !!imagesContainer.querySelector('.sort-item')

      if (hasImage) {
        const uppyBtn = imagesContainer.querySelector('.sort-ignore')
        uppyBtn.style.display = 'none'
      }
    }

  }
}
