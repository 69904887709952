export default class PhotoUploaderEvents {
  constructor(area_selector = "#drag-drop-area") {
    this.area_selector = area_selector;
    this.uploader_dataset = this.get_uploader_dataset();
    this.accessToken = 'TZZfk4bwoEGbHqr6Zog9Z76NsDkpcLgzDT3b15AQ'
    this.uploader_acceess_token = this.uploader_dataset.accessToken;
    this.init();
  }

  area() {
    return document.querySelector(this.area_selector);
  }

  get_uploader_dataset() {
    return this.area().dataset;
  }

  init() {
    const Uppy = require("@uppy/core");
    const uk_UA = require("../uk_UA");
    const Dashboard = require("@uppy/dashboard");
    const XHRUpload = require("@uppy/xhr-upload");
    const ImageEditor = require("@uppy/image-editor");
    const TextRestrictions =
      "Максимальні розміри для завантаженя: 5000x5000 пікселів, 15 мб. Доступні формати для завантаженя: JPG, PNG";

    Object.keys(window).forEach((key) => {
      if (/^on/.test(key)) {
        window.addEventListener(key.slice(2), (event) => {
          const insertWrap = document.querySelector(
            ".uppy-Dashboard-AddFiles-info"
          );
          if (insertWrap !== null && insertWrap.childElementCount === 0) {
            const innerTag = document.createElement("div");
            innerTag.classList.add("uppy-Dashboard-note");
            innerTag.innerHTML =
              "Максимальні розміри для завантаженя: <strong>5000x5000 пікселів, 15 мб</strong> <br> Доступні формати для завантаженя: <strong>JPG, PNG</strong>";
            insertWrap.appendChild(innerTag);
          }
        });
      }
    });

    document
      .querySelector("#sortable")
      .addEventListener("touchstart", function(event) {
        let eventDragenter = new Event("dragenter");
        let eventDragover = new Event("dragover");
        let eventPointerdown = new Event("pointerdown");
        let element = document.querySelector("#sortable");
        element.dispatchEvent(eventDragenter);
        element.dispatchEvent(eventDragover);
        element.dispatchEvent(eventPointerdown);
        if (event.target.classList.contains("UppyModalOpenerBtn")) {
          uppy.getPlugin("Dashboard").openModal();
        }
      });

    const uppy = Uppy({
      locale: uk_UA,
      autoProceed: false,
      meta: {
        event_id: this.uploader_dataset.eventId,
      },
      restrictions: {
        maxFileSize: 1024 * 1024 * 15,
        // max number of attached files
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        allowedFileTypes: [".jpg", ".jpeg", ".jfif", ".jpe", ".png"],
      },
    });

    uppy.use(Dashboard, {
      browserBackButtonClose: true,
      closeModalOnClickOutside: true,
      disablePageScrollWhenModalOpen: false,
      inline: false,
      proudlyDisplayPoweredByUppy: false,
      replaceTargetContent: true,
      showProgressDetails: true,
      target: this.area_selector,
      trigger: ".UppyModalOpenerBtn",
      height: 470,
    });

    uppy.on('file-added', () => {

      setTimeout(() => {
        const uploadButton = document.querySelector('.uppy-StatusBar-actionBtn--upload');
        if (uploadButton) {
          uploadButton.addEventListener('click', function (event) {
            event.preventDefault();
            uppy.getPlugin('Dashboard').closeModal();
          }, { once: true });
        }
      }, 100);
    });

    uppy.on('file-removed', (file) => {
      setTimeout(() => {
        const uploadButton = document.querySelector('.uppy-StatusBar-actionBtn--upload');
        if (uploadButton) {
          uploadButton.addEventListener('click', function (event) {
            event.preventDefault();
            uppy.getPlugin('Dashboard').closeModal();
          }, { once: true });
        }
      }, 100);
    });

    uppy.on('dashboard:modal-closed', () => {
      const files = uppy.getFiles();
      const imagesContainer = document.getElementById('sortable');
      // imagesContainer.innerHTML = '';
      if (imagesContainer.querySelectorAll('.sort-item').length > 0) {
        imagesContainer.querySelectorAll('.sort-item').forEach(item => {
          item.remove()
        })
      }

      files.forEach(file => {
        const reader = new FileReader();

        reader.onload = function(event) {
          const imageUrl = event.target.result;
          const photoContainer = document.createElement('div');
          photoContainer.classList = 'photo-container sort-item'
          const photoWrapper = document.createElement('div');
          photoWrapper.classList = 'photo-wrapper'
          const imageLink = document.createElement('a');
          imageLink.classList = 'photo-link'
          imageLink.style.backgroundImage = "url("+imageUrl+")"
          imageLink.dataset.uppy = file.id

          const rotationContainer = document.createElement('div');
          rotationContainer.classList = 'rotation-container'
          const rotationLeft = document.createElement('a');
          rotationLeft.classList = 'rotation-left'
          const rotationRight = document.createElement('a');
          rotationRight.classList = 'rotation-right'
          const removeContainer = document.createElement('a');
          removeContainer.classList = 'remove'
          removeContainer.dataset.uppy = file.id

          // rotationContainer.append(rotationLeft)
          // rotationContainer.append(rotationRight)

          const imageElement = document.createElement('img');
          imageElement.src = imageUrl;

          photoWrapper.append(imageLink)
          photoWrapper.append(rotationContainer)
          photoWrapper.append(removeContainer)
          photoContainer.append(photoWrapper)

          document.getElementById('sortable').append(photoContainer);

          let rotation = 0;

          const rightBtns = document.querySelectorAll('a.rotation-right')
          const leftBtns = document.querySelectorAll('a.rotation-left')

          function rotateBase64Image(base64, angle) {
            return new Promise((resolve, reject) => {

              const img = new Image();

              img.src = base64;


              img.onload = function () {

                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");

                canvas.width = img.width;
                canvas.height = img.height;

                ctx.translate(img.width / 2, img.height / 2); // переміщуємо по центру зображення
                ctx.rotate((angle * Math.PI) / 180); // обертаємо на заданий кут
                ctx.drawImage(img, -img.width / 2, -img.height / 2); // малюємо обернуте зображення

                const rotatedBase64 = canvas.toDataURL("image/png");

                resolve(rotatedBase64);
              };

              img.onerror = function () {
                reject(new Error("Помилка завантаження зображення."));
              };
            });
          }

          rightBtns.forEach(function(button) {
            button.addEventListener('click', (event)=>{
              const base64Image = imageUrl
              rotation += 90;

              rotateBase64Image(base64Image, rotation)
                .then(rotatedBase64 => {
                  console.log("Rotated");
                })
                .catch(error => {
                  console.error("Rotate error", error);
                });
              event.target.closest('.photo-wrapper').querySelector('.photo-link').style.transform = `rotate(${rotation}deg)`
            })
          } )

          leftBtns.forEach(function(button) {
            button.addEventListener('click', (event)=>{
              rotation -= 90;
              event.target.closest('.photo-wrapper').querySelector('.photo-link').style.transform = `rotate(${rotation}deg)`
            })
          } )

          const deleteBtns = document.querySelectorAll('a.remove')
          deleteBtns.forEach(function(button) {
            button.addEventListener('click', (event)=>{
              button.closest('.photo-container').remove()
              let fileId = button.dataset.uppy
              uppy.removeFile(fileId)
              const hiddenInputs = hiddenFieldsContainer.querySelectorAll('input')
              hiddenInputs.forEach(input => {
                if (input.id === fileId) {
                  input.remove()
                }
              })
            })
          } )
        };

        reader.onerror = function(event) {
          console.error('Помилка читання файлу:', event.target.error);
        };

        reader.readAsDataURL(file.data);
      });
    })

      function submitForm() {
        const form = document.getElementById('new_event')
        const formData = new FormData(form);

      uppy.getFiles().forEach(file => {
        formData.append('files[]', file.data, file.name);
      });

      const otherFormData = new FormData(document.getElementById('new_event'));
      for (let pair of otherFormData.entries()) {
        formData.append(pair[0], pair[1]);
      }

      const preloader = document.getElementById('loader')
      const overlay = document.getElementById('overlay')

      overlay.style.display = 'block';
      preloader.style.display = 'block';

      fetch(form.action, {
        method: 'POST',
        body: formData
      })
      .then(response => response)
        .then(result => {
          console.log(result.status);

          if (result.status === 200) {
            setTimeout(function () {
              const currentUrl = window.location.href;
              const parts = currentUrl.split('/');
              const lastPart = parts[parts.length - 1];
              const statusFieldValue = parseInt(formData.get('event[status]'));

              if (lastPart === 'new') {
                if (statusFieldValue === 1) {
                  const newUrl = currentUrl.replace('/new', '/relevant');
                  window.location.replace(newUrl);
                }
                if (statusFieldValue === 0) {
                  const newUrl = currentUrl.replace('/new', '/outdated');
                  window.location.replace(newUrl);
                }
              }
            }, 300);

          } else if (result.status === 422 ){
              const error = document.getElementById('errorBox')
              error.classList.remove('error-required__hide')
              window.scrollTo({
                top: 50,
                left: 0,
                behavior: "smooth",
              });

              const editorField = document.getElementById('event_description')
              console.log(editorField.value.length);

              if (editorField && editorField.value.length === 0) {
                editorField.classList.add('error-field')
              } else {
                editorField.classList.remove('error-field')
              }

            document.getElementById('newEvent').addEventListener('click', function clickHandler() {
                submitForm();
                document.getElementById('newEvent').removeEventListener('click', clickHandler);
              });
            }

           else {
              const newUrl = window.location.href.replace('/new', '/active');
              window.location.replace(newUrl);
              alert('Під час збереження події виникла помилка. Будь ласка, зверніться до служби підтримки для отримання додаткової інформації.');
              }

              overlay.style.display = 'none';
              preloader.style.display = 'none';

              })
              .catch(error => {
                console.error('There was a problem with the fetch operation:', error);
                document.getElementById('newEvent').addEventListener('click', function clickHandler() {
                  submitForm();
                  document.getElementById('newEvent').removeEventListener('click', clickHandler);
                });
              });
        }
      document.getElementById('newEvent').addEventListener('click', function clickHandler() {
        submitForm();
        document.getElementById('newEvent').removeEventListener('click', clickHandler);
      });

    uppy.use(ImageEditor, {
      target: Dashboard,
      id: "ImageEditor",
      quality: 1.0,
      cropperOptions: {
        viewMode: 1,
        background: false,
        autoCropArea: 1,
        responsive: true,
      },
      actions: {
        revert: true,
        rotate: true,
        flip: true,
        zoomIn: true,
        zoomOut: true,
        cropSquare: true,
        cropWidescreen: true,
        cropWidescreenVertical: true,
      },
    });

  }
}
